import { graphql, Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import Container from '../../components/Container'
import InPageNav from '../../components/InPageNav'
import Layout from '../../components/Layout'
import PageHeader from '../../components/PageHeader'
import Section from '../../components/Section'
import SEO from '../../components/SEO'

const AfterParallaxWrap = styled.div`
  position: relative;
  z-index: 2;
`

const QandAContainer = styled.ul`
  max-width: 740px;
  margin-left: auto;
  margin-right: auto;

  > li {
    + li {
      margin-top: 25px;
    }

    h3 {
      margin-bottom: 12px;

      &.policy-dont {
        color: ${props => props.theme.colors.roseGold};
      }
    }

    p {
      font-size: 14px;
      line-height: 22px;
    }
  }
`

const Support = ({ data }) => {
  const policies = data.allContentfulPolicy.edges

  return (
    <Layout>
      <SEO />

      <PageHeader
        image={data.allContentfulAsset.edges[0].node}
        title="Support"
      />
      <AfterParallaxWrap id="stickyNavContainer">
        <InPageNav id="stickyNav">
          <Link to="/support/" activeClassName="active-page-link">
            Artist FAQs
          </Link>
          <Link to="/support/customers/" activeClassName="active-page-link">
            Customers
          </Link>
        </InPageNav>
        <Container wrap="true" afterStickyNav="true">
          <Section>
            <QandAContainer>
              {policies.map(({ node: policy }) => (
                <li key={policy.id}>
                  <h3
                    className={`section-title--secondary ${
                      policy.allowed ? '' : 'policy-dont'
                    }`}
                  >
                    {policy.allowed ? 'Do' : `Don't`}
                  </h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: policy.body.childMarkdownRemark.html,
                    }}
                  />
                </li>
              ))}
            </QandAContainer>
          </Section>
        </Container>
      </AfterParallaxWrap>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulPolicy {
      edges {
        node {
          createdAt
          id
          body {
            childMarkdownRemark {
              html
            }
          }
          allowed
        }
      }
    }
    allContentfulAsset(filter: { title: { eq: "news-hero-image" } }) {
      edges {
        node {
          title
          fluid(quality: 80) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`

export default Support
