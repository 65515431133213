import React, { useEffect } from 'react'
import styled from 'styled-components'

const parallaxBgInitialScale = 1.25

const Title = styled.h1`
  position: relative;
  max-width: 750px;
  margin-top: ${props => props.titleMarginTopSm || '10px'};
  padding-left: 20px;
  padding-right: 20px;
  font-size: ${props =>
    props.titleFsSm || props.theme.typography.headlineHero.fsSm};
  line-height: ${props =>
    props.titleLhSm || props.theme.typography.headlineHero.lhSm};
  color: white;
  text-align: center;

  span {
    display: inline-block;
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    font-size: ${props =>
      props.titleFzLg || props.theme.typography.headlineHero.fsLg};
    line-height: ${props =>
      props.titleLhLg || props.theme.typography.headlineHero.lhLg};
    margin-top: ${props => props.titleMarginTopLg || '0'};
  }
`

const Wrapper = styled.header`
  position: relative;
  overflow: hidden; // Check more pages if this is okay
  display: flex;
  /* justify-content: flex-start; */
  align-items: center;
  width: 100%;
  ${props =>
    props.noHeroSection
      ? `height: ${props.theme.navMenu.heightSm};`
      : `height: ${props.heightSm || '200px'};`}

  .gatsby-image-wrapper {
    background-size: ${props => props.bgfit || 'cover'};
    background-position: ${props => props.bgPosition || '50% 50%'};
    background-repeat: no-repeat;
  }

  &#parallaxPage {
    /* top: -${props => props.theme.navMenu.heightSm}; */
    justify-content: center;

    .gatsby-image-wrapper {
      transform: scale(${parallaxBgInitialScale});
    }

    /* + div:first-of-type {
      margin-top: -${props => props.theme.navMenu.heightSm};
    } */
  }

  @media screen and (max-width: ${props => props.theme.responsive.underLarge}) {
    .menu-active & {
      z-index: 1;
    }
  }

  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    ${props =>
      props.noHeroSection
        ? `height: ${props.theme.navMenu.heightSm};`
        : `height: ${props.heightMd || '300px'};`}

    &#parallaxPage {
      /* + div:first-of-type {
        margin-top: ${props => props.heightMd || '300px'};
      } */
    }
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    ${props =>
      props.noHeroSection
        ? `height: ${props.theme.navMenu.heightLg};`
        : `height: ${props.heightLg || '400px'};`}

    &#parallaxPage {
      /* + div:first-of-type {
        margin-top: ${props => props.heightLg || '400px'};
      } */
    }
  }
`

const ImgAsBG = styled.div`
  position: absolute !important;
  width: 100%;
  height: ${props => props.heightSm || '200px'};

  @media (min-width: ${props => props.theme.responsive.medium}) {
    height: ${props => props.heightMd || '300px'};
  }

  @media (min-width: ${props => props.theme.responsive.large}) {
    height: ${props => props.heightLg || '400px'};
  }

  > img {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    opacity: 1;
    object-fit: ${props => props.bgFit || 'cover'} !important;
    object-position: ${props => props.bgPosition || '50% 50%'} !important;
  }
`

const PageHeader = props => {
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {
      capture: true,
      passive: true,
    })
  })

  const handleScroll = event => {
    const scrollPos = window.scrollY
    const heroSection = document.getElementById('parallaxPage')
    const heroImg = document.querySelector(
      '#parallaxPage > .gatsby-image-wrapper'
    )
    const heroHeadline = document.querySelector('#parallaxPage > h1')
    const heroNav = document.querySelector('#parallaxPage > nav')
    const heroHeight = heroSection && heroSection.offsetHeight
    const heroOutOfView = heroSection && scrollPos / heroHeight
    // Returns float
    // 0 = fully in view >= 1 = fully out of view
    const parallaxBgMultiplier = 0.1
    // Multiplier must be <= 1
    // Smaller fractions will perform (and look) better
    const parallaxHeadlineMultiplier = 0.1
    const parallaxNavMultiplier = 0.03
    if (heroSection) {
      // heroSection.style.transform = `translateY(-${heroOutOfView *
      //   heroHeight *
      //   parallaxBgMultiplier}px)`
      heroImg.style.transform = `translateY(-${heroOutOfView *
        heroHeight *
        parallaxBgMultiplier}px) scale(${(parallaxBgInitialScale * 100 -
        scrollPos / (heroHeight * 0.2)) /
        100})`
      // heroImg.style.transform = `scale(${(parallaxBgInitialScale * 100 -
      //   scrollPos / (heroHeight * 0.1)) /
      //   100})`
    }
    // if (heroHeadline) {
    //   heroHeadline.style.transform = `translateY(-${heroOutOfView *
    //     heroHeight *
    //     parallaxHeadlineMultiplier}px)`
    // }
    // if (heroNav) {
    //   heroNav.style.transform = `translateY(-${heroOutOfView *
    //     heroHeight *
    //     parallaxNavMultiplier}px)`
    // }
  }

  return (
    <>
      <Wrapper
        id={props.noHeroSection || props.noParallax ? '' : 'parallaxPage'}
        noHeroSection={props.noHeroSection}
        heightSm={props.heightSm}
        heightMd={props.heightMd}
        heightLg={props.heightLg}
      >
        {props.image && (
          <ImgAsBG
            className="gatsby-image-wrapper"
            style={{ backgroundImage: `url(${props.image.fluid.src})` }}
            heightSm={props.heightSm}
            heightMd={props.heightMd}
            heightLg={props.heightLg}
            fluid={props.image.fluid}
          >
            <img src={props.image.fluid.src} />
          </ImgAsBG>
        )}
        <Title
          className="headline--hero"
          titleFsSm={props.titleFsSm}
          titleLhSm={props.titleLhSm}
          titleFsLg={props.titleFsLg}
          titleLhLg={props.titleLhLg}
          titleMarginTopSm={props.titleMarginTopSm}
          titleMarginTopLg={props.titleMarginTopLg}
          dangerouslySetInnerHTML={{ __html: props.title }}
        />
      </Wrapper>
    </>
  )
}

export default PageHeader
