import React, { useEffect } from 'react'
import styled from 'styled-components'

const Outer = styled.div`
  position: absolute;
  z-index: 4;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: ${props => props.theme.inPageNav.heightSm};
  background-color: white;
  border-bottom: 1px solid ${props => props.theme.colors.lightBorder};
  transition: height 150ms ease-in-out, box-shadow 0s;

  > div {
    width: max-content;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-size: ${props => props.theme.inPageNav.fsSm};
    line-height: ${props => props.theme.inPageNav.lhSm};
    font-weight: 500;
    text-align: center;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    > div {
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-end;

      a {
        position: relative;
        flex-shrink: 0;
        white-space: nowrap;
        padding: 15px;
        color: ${props => props.theme.colors.grayText};
        text-decoration: none;

        &:hover {
          color: ${props => props.theme.colors.darkText};
        }

        &.active-page-link {
          font-weight: 600;
          color: ${props => props.theme.colors.darkText};

          &::after {
            content: '';
            position: absolute;
            z-index: 1;
            width: calc(100% - 30px); // left + right padding on <a>
            height: 2px;
            bottom: 0;
            left: 15px; // match left padding on <a>
            background-color: ${props => props.theme.colors.roseGold};
          }
        }

      }
    }
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    height: ${props => props.theme.inPageNav.heightLg};

    > div {
      > div {
        a {
          padding: 20px;

          &.active-page-link {
            &::after {
              width: calc(100% - 40px); // left + right padding on <a>
              left: 20px; // match left padding on <a>
            }
          }
        }
      }
    }
  }

  &.is-stuck {
    position: fixed;
    box-shadow: 0 0 5px 8px rgba(0, 0, 0, 0.02);

    > div {
      > div {
        a {
          // padding: 10px 15px 5px;
        }
      }
    }

    &.is-at-bottom {
      position: absolute;
      top: initial;
      bottom: 0;
      box-shadow: none;
    }

    @media screen and (min-width: ${props => props.theme.responsive.large}) {
      height: 53px; // lh + pt + pb

      > div {
        > div {
          a {
            // padding: 20px 20px 7px;
            // margin-bottom: 10px;
          }
        }
      }
    }

    &.no-animate {
      transition: all 0s !important;
    }
  }
}
`

const InPageNav = props => {
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {
      capture: true,
      passive: true,
    })
    // Use refactored function from below once refactored
    handleScroll(null, true)
  })

  const handleScroll = (e, noAnimate = false) => {
    const stickyContainer = document.getElementById('stickyNavContainer')
    const stickyNav = document.getElementById('stickyNav')
    const stickyNavHeight = stickyNav && stickyNav.offsetHeight
    const stickyContainerTopFromWinTop =
      stickyContainer && stickyContainer.getBoundingClientRect().top
    const stickyContainerBottomFromWinTop =
      stickyContainer &&
      stickyContainer.getBoundingClientRect().bottom - stickyNavHeight

    // Refactor into new function that listens for transitionEnd event
    if (noAnimate && stickyContainer)
      stickyContainer.querySelector('#stickyNav').classList.add('no-animate')
    if (noAnimate && stickyContainer) {
      setTimeout(
        () =>
          stickyContainer
            .querySelector('#stickyNav')
            .classList.remove('no-animate'),
        50
      )
    }

    if (stickyContainer && stickyContainerTopFromWinTop < 0) {
      stickyContainer.querySelector('#stickyNav').classList.add('is-stuck')

      if (stickyContainer && stickyContainerBottomFromWinTop < 0) {
        stickyContainer
          .querySelector('#stickyNav')
          .classList.add('is-at-bottom')
      } else if (stickyContainer && stickyContainerBottomFromWinTop >= 0) {
        stickyContainer
          .querySelector('#stickyNav')
          .classList.remove('is-at-bottom')
      }
    } else if (stickyContainer && stickyContainerTopFromWinTop >= 0) {
      stickyContainer.querySelector('#stickyNav').classList.remove('is-stuck')
    }
  }

  return (
    <Outer id={props.id}>
      <div>
        <div>{props.children}</div>
      </div>
    </Outer>
  )
}

export default InPageNav
