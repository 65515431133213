import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.section`
  margin: ${props => props.theme.spacing.section.topSm} auto
    ${props => props.theme.spacing.section.bottomSm};
  width: 100%;
  flex-grow: 1;

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    margin-top: ${props => props.theme.spacing.section.topLg};
    margin-bottom: ${props => props.theme.spacing.section.bottomLg};
  }
`

const Section = props => {
  return <Wrapper>{props.children}</Wrapper>
}

export default Section
